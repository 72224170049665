import worldmap from "../Assets/worldmap.png";
import ntakes from "../Assets/ntakes.png";
import chess from "../Assets/chest.png";
import datamaps from "../Assets/datamaps.png";
import findmde from "../Assets/findme.png";
import datachambre from "../Assets/datachambre.png";

export const projects = [
    {
        "title": "DATACHAMBRE",
        "date": "2019",
        "image": datachambre,
        "description": "Developed with React front side and Node.js back side for La Chambre d'Agriculture du Rhône, the mission was to propose a full web solution to migrate their microsoft access databases.",
        "link": "https://florentin-besset.com/chambre"
    },
    {
        "title": "FINDME",
        "date": "Spring, 2019",
        "image": findmde,
        "description": "Developed with VueJS front side and Node.js back side. Multiplayer game where the aim is to find other players and obviously not to be find, wokring via the geolocation.",
    },
    {
        "title": "DATAMAPS",
        "date": "Winter, 2018",
        "image": datamaps,
        "description": "Co-developed website. We used Jquery for frontend and php for backend. The website allows us to know the availability of self-service bike stations in Lyon. We also did some statistics between stations, time laps etc...",
        "link": "https://florentin-besset.com/datamaps"
    },
    {
        "title": "CHESS",
        "date": "Spring, 2017",
        "image": chess,
        "description": "Coded in Java. A simple customized chess game."
    },
    {
        "title": "NTAKES",
        "date": "Spring, 2016",
        "image": ntakes,
        "description": "Coded in vanilla Js and php. A multiplayer card game based on '6 takes!'."
    },
    {
        "title": "WORLD MAP GENERATOR",
        "date": "Winter, 2015",
        "image": worldmap,
        "description": "Co-programmed in language C. A tool to generate world maps where the user can customize the percentage of field, ocean... place some characters or special places."
    }
];