import React from 'react';
import {projects} from "./projects";
import styled from "styled-components";
import {Container} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";

const CustomContainer = styled(Container)`
    overflow: auto;
    background-color: white;
    padding: 0;
    margin: 15px auto;
    max-width: 920px;
    border: solid 1px rgba(160,160,160,0.3);
`;
const WrapperProject = styled.div`
    height: 500px;
    margin: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
    border: solid 2px rgba(160, 160, 160, 0.3);
    overflow: hidden;
`;
const Content = styled.div`
    height: 400px;
    width: 100%;
    background-image: ${props => `url(${props.img})`};
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.2s ease-out;
    filter: blur(2px);
    &:hover{
        transform: scale(1.05);
    }
`;
const Top = styled.div`
    height: 100px;
    width: 100%;
    z-index: 2;
    background-color: white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;
const Bottom = styled.div`
    min-height: 100px;
    width: 100%;
    padding: 15px;
    position: absolute;
    bottom: 0;
    border-top: solid 1px rgba(160, 160, 160, 0.3);
    background-color: rgba(255, 255, 255, 0.94);
    transition: background-color 0.1s;
    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
    }
`;
const Header = styled.div`
    border-bottom: solid 1px rgba(160, 160, 160, 0.3);
    text-align: center;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
`;

//Project component
class Project extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }
    render() {
        return (
            <CustomContainer>
                <Header><h3><b>SOME OF MY PROJECTS</b></h3></Header>
                {
                    projects.map(project =>
                        <WrapperProject>
                            <Top>
                                <Left>
                                    <b>{project.title}</b>
                                    <span>{project.date}</span>
                                </Left>
                                <div>
                                    {project.link !== undefined &&
                                    <h5 style={{color: "#0056c3", cursor: "pointer"}} onClick={() => window.open(project.link, '_blank')}>
                                        Go to <FontAwesomeIcon icon={faArrowCircleRight}/></h5>
                                    }
                                </div>
                            </Top>
                            <Content img={project.image}/>
                            <Bottom>
                                <span>{project.description}</span>
                            </Bottom>
                        </WrapperProject>
                    )
                }
            </CustomContainer>
        )
    }
}

export default Project;