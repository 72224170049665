import React from 'react';
import ReactDOM  from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App/App';
import * as serviceworker from './serviceWorker';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5Z43PG2',
  events: {
    contact: 'Contact page loaded',
    homepage: 'Homepage page loaded'
  }
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'));

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/cra-pwa
serviceworker.unregister();
