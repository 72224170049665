import React from '../Assets/react.png';
import Flask from '../Assets/flask.png';
import Elasticsearch from '../Assets/elasticsearch.png';
import HtmlCssJs from '../Assets/htmlcssjs.png';
import GraphQL from '../Assets/graphql.png';
import Mongo from '../Assets/mongo.png';
import Kmm from '../Assets/kmm.png';
import Nodejs from '../Assets/nodejs.png';
import Postgres from '../Assets/postgres.png';
import Kafka from '../Assets/kafka.png';
import AntDesign from '../Assets/antdesign.png';
import MaterialUI from '../Assets/materialui.png';
import Springboot from '../Assets/springboot.png';
import Docker from '../Assets/docker.png';
import Kubernetes from '../Assets/kubernetes.png';

export const skills = [
    {
        "title": "ReactJS",
        "image": React
    },
    {
        "title": "React Native",
        "image": React
    },
    {
        "title": "KMM",
        "image": Kmm
    },
    {
        "title": "Html Css Js Ts",
        "image": HtmlCssJs
    },
    {
        "title": "Ant Design",
        "image": AntDesign
    },
    {
      "title": "Material-UI",
      "image": MaterialUI
    },
    {
        "title": "GraphQL",
        "image": GraphQL
    },
    {
      "title": "Spring Boot",
      "image": Springboot
    },
    {
        "title": "Node.JS",
        "image": Nodejs
    },
    {
        "title": "Flask",
        "image": Flask
    },

    {
        "title": "MongoDB",
        "image": Mongo
    },
    {
        "title": "PostgreSQL",
        "image": Postgres
    },
    {
        "title": "Kafka",
        "image": Kafka
    },
    {
      "title": "Elasticsearch",
      "image": Elasticsearch
    },
    {
      "title": "Docker",
      "image": Docker
    },
    {
      "title": "Kubernetes",
      "image": Kubernetes
    }
];