import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import styled from "styled-components";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Project from "../Projects/Project";
import TopBar from "../TopBar/TopBar";

const Wrapper = styled('div')({
    width: "100%",
    height: "100%",
    backgroundColor: "#e9ebee",
    overflow: "auto",
});


const PageLayout = () => (
  <Wrapper >
    <TopBar />
    <Outlet />
  </Wrapper>
);

const Routing = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route element={<PageLayout />}>
                    <Route path="/" element={<About />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/projects" element={<Project />}/>
                    <Route path="/contact" element={<Contact />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Routing;