import React from 'react';
import {Container, Jumbotron, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {data} from "./data";
import classnames from "classnames";
import Experience from "./Experience";
import Studies from "./Studies";
import styled from "styled-components";
import FloPic from "../Assets/profilCircle.jpg";
import bgVideo from "../Assets/bgVideoCompressed.mp4";
import WorkSkills from "./WorkSkills";
import TagManager from "react-gtm-module";

const ProfilPic = styled.img`
    height: 16vmin;
    max-height: 185px;
    margin-right: 10px;
    border-radius: 100px;
    @media (max-width: 767px) {
        margin: 0 auto;
        width: 20vmin;
        height: 20vmin;
        border-radius: 100px;
    }
`;
const CustomJumbotron = styled(Jumbotron)`
    padding: 0;
    position: relative;
    height: 220px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
        height: 300px;
    }
    @media (max-width: 767px) {
        height: 440px;
    }
    @media (max-width: 575px) {
        height: 500px;
    }
`;
const CustomContainer = styled(Container)`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    justify-content: center;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;
const CustomNavItem = styled(NavItem)`
    cursor: pointer;
`;
const CustomVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const Title = styled.h1`
    text-align: center;
    @media (max-width: 767px) {
        font-size: 3.5em;
    }
`;
const Info = styled.p`
    text-align: center;
    @media (max-width: 767px) {
        font-size: 1.2rem;
    }
`;
const CustomNavLink = styled(NavLink)`
    color: black;
    @media (max-width: 375px) {
        padding: .5rem 0.5rem;
    }
`;

class About extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
        TagManager.dataLayer({
          dataLayer: {
            event: 'homepage'
          }
        });
        return (
            <div>
                <CustomJumbotron>
                    <CustomVideo className="timeVideo" loop="loop" preload="auto" autoPlay muted><source type="video/mp4" src={bgVideo}/></CustomVideo>
                    <CustomContainer>
                        <ProfilPic src={FloPic}/>
                        <div>
                            <Title className="display-3">{data.title}</Title>
                            <Info className="lead">{data.summary}</Info>
                        </div>
                    </CustomContainer>
                </CustomJumbotron>
                <Container style={{paddingBottom: "15px"}}>
                    <Nav tabs style={{borderBottom: "1px solid #00325273"}}>
                        <CustomNavItem>
                            <CustomNavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                Experience
                            </CustomNavLink>
                        </CustomNavItem>
                        <CustomNavItem>
                            <CustomNavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                Education
                            </CustomNavLink>
                        </CustomNavItem>
                        <CustomNavItem>
                            <CustomNavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                Work skills
                            </CustomNavLink>
                        </CustomNavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} style={{backgroundColor: "white"}}>
                        <TabPane tabId="1">
                            <Experience/>
                        </TabPane>
                        <TabPane tabId="2">
                            <Studies/>
                        </TabPane>
                        <TabPane tabId="3">
                            <WorkSkills/>
                        </TabPane>
                    </TabContent>
                </Container>
            </div>
        )
    }
}

export default About;