import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import {skills} from "./skills";
import styled from "styled-components";

const Card = styled.div`
    width: 20%;
    min-width: 210px;
    padding: 10px;
    height: 100pxrder: 1px solid black;
    margin: 10px;
    height: 150px;
    background-color: #e9ebee;
    display: flex;
    flex-direction: column;
    border: 1px solid #80808038;
    @media (max-width: 767px) {
        width: 100%;
    }
`;
const CustomCol = styled(Col)`
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    padding: 15px;
`;
const Logo = styled.img`
    display: block;
    height: 100%;
    margin: 0 auto;
`;
const LogoWrapper = styled.div`
    height: 80%;
`;

class WorkSkills extends React.Component {
    render() {
        return <Container>
            <Row>
                <CustomCol>
                    {skills.map(skill => {
                        return <Card>
                            <LogoWrapper><Logo src={skill.image}/></LogoWrapper>
                            <div><h4 style={{textAlign: "center"}}>{skill.title}</h4></div>
                        </Card>
                    })}
                </CustomCol>
            </Row>
        </Container>
    }
}

export default WorkSkills;