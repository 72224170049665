import React from 'react';
import {Collapse, Navbar, NavbarToggler, Nav} from 'reactstrap';
import {Link} from "react-router-dom";
import styled from "styled-components";
import FloPic from "../Assets/profilCircle.jpg";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Li = styled.li`
    @media (min-width: 768px) {
        &:not(:first-child) {
            margin-left: 1em;
            padding-left: 1em;
            border-left: 1px solid hsla(0,0%,100%,.5);
        };
    }
    line-height: 1;
`;
const Brand = styled.a`
    white-space: nowrap;
    margin: 0;
    padding-right: 1.5em;
    @media (min-width: 768px) {
        border-right: 1px solid rgba(255, 255, 255, 0.81);
    }
`;
const Links = styled(Collapse)`
    justify-content: space-between;
    @media (min-width: 768px) {
        padding-left: 1.5em;
    }
`;
const MainNav = styled(Navbar)`
    line-height: 3.5em;
    display: flex;
    width: 100%;
    padding: 0 1.5em 0;
`;
const CustomLink = styled(Link)`
    color: white;
    display: block;
    color: hsla(0,0%,100%,.5);
    text-decoration: none;
    &:hover {
        color: hsla(0,0%,100%,.75);
    }
    @media (max-width: 767px) {
         padding: .5rem 0;
    }
`;
const ProfilPic = styled.img`
    height: 5vmin;
    margin-right: 10px;
    border-radius: 50px;
`;

class TopBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <MainNav style={{ backgroundColor: "#343a40" }} dark expand="md">
                <Brand href="/" className="navbar-brand">
                    <ProfilPic src={FloPic} alt="Florentin Besset"/>
                    <span>Florentin Besset</span>
                </Brand>
                <NavbarToggler onClick={this.toggle}/>
                <Links isOpen={this.state.isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <Li key="about">
                            <CustomLink to={"/about"}>About</CustomLink>
                        </Li>
                        <Li key="projects">
                            <CustomLink to={"/projects"}>Projects</CustomLink>
                        </Li>
                        <Li key="contact">
                            <CustomLink to={"/contact"}>Contact</CustomLink>
                        </Li>
                    </Nav>
                    <FontAwesomeIcon icon={faLinkedin} style={{color: "#0174b1", cursor: "pointer", fontSize: "1.3em"}} onClick={() => window.open("https://www.linkedin.com/in/florentin-besset/", '_blank')}/>
                </Links>
            </MainNav>
        );
    }
}

export default TopBar;