import Lyon1 from '../Assets/lyon1.png';
import Antidot from '../Assets/antidot.png';
import GroupeMutuel from '../Assets/groupemutuel.png';
import moment from "moment";

export const data = {
  "title": "IT Engineer",
  "summary": `I am a ${moment().diff('1997-02-21', 'years')}-year-old Swiss-French computer science enthusiast currently residing in Riddes, Switzerland. My passion lies in software development, automation, and optimization, where I enjoy leveraging technology to solve complex problems. With a keen interest in cutting-edge technology, I strive to stay up-to-date with the latest trends and advancements in the field.`,
  "studies": [
    {
      "institute": "Université Claude Bernard Lyon 1",
      "logo": Lyon1,
      "url": "https://www.univ-lyon1.fr/",
      "title": "Master degree in computer science",
      "durationInYears": "2",
      "graduationYear": 2020
    },
    {
      "institute": "Université Claude Bernard Lyon 1",
      "logo": Lyon1,
      "url": "https://www.univ-lyon1.fr/",
      "title": "Bachelor in computer science",
      "durationInYears": "3",
      "graduationYear": 2018
    }
  ],
  "experiences": [
    {
      "companyName": "Groupe Mutuel",
      "subCompanyName": "(ext provider via Talents Connection SARL)",
      "logo": GroupeMutuel,
      "url": "https://www.groupemutuel.ch/",
      "roles": [
        {
          "title": "Mobile developer",
          "description": "Development of android & ios insureds customer portal.",
          "startDate": "2020-09-14",
          "currentJob": true,
          "location": "Martigny, Switzerland"
        },
        {
          "title": "Web developer",
          "description": "Development of web insureds customer portal. Also worked on the services portal for companies.",
          "startDate": "2020-09-14",
          "currentJob": true,
          "location": "Martigny, Switzerland"
        }
      ]
    },
    {
      "companyName": "Antidot",
      "subCompanyName": "",
      "logo": Antidot,
      "url": "https://www.antidot.net/",
      "roles": [
        {
          "title": "Web developer",
          "description": "Developed several web applications in presales team to automate team tasks and increase efficiency. These applications included a customer dashboard, a deployment tool to streamline product deployment, and a developer hub to enhance collaboration and knowledge sharing.",
          "startDate": "2018-09-10",
          "endDate": "2020-09-04",
          "location": "Lyon, France"
        },
        {
          "title": "Customer relationship",
          "description": "Help some of our prospects, customize our product for them, answer their questions.",
          "startDate": "2018-09-10",
          "endDate": "2020-09-04",
          "location": "Lyon, France"
        },
        {
          "title": "Intern Web developer",
          "description": "Development of Web Applications",
          "startDate": "2018-02-01",
          "endDate": "2018-07-31",
          "location": "Lyon, France"
        }
      ]
    }
  ]
};
