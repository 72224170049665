import React from "react";
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import {data} from "./data.js";
import moment from "moment";
import { Media } from "reactstrap";

const CustomMedia = styled(Media)`
    display: flex;
    flex-direction: row;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

function getDuration(duration) {
    const years = parseInt(duration / 12);
    const months = (duration > 12)? duration % 12 : duration;
    return (years > 0? years + " year" + (years > 1? "s": "") + (months > 0? " and ": "") : "") + (months > 0? months + " month" + (months > 1? "s": "") : "");
}

//Experience component
class Experience extends React.Component {
    render() {
        return <Container>
            <Row>
                <Col>
                    {data.experiences.map(function (experience, i) {
                        moment.locale('en');
                        const totalDuration = experience.roles.map(role => {
                            const startDate = moment(role.startDate);
                            const timeEnd = moment(role.currentJob ? new Date() : new Date(role.endDate));
                            const duration = moment.duration(timeEnd.diff(startDate));
                            return Math.round(Number(duration.asMonths()));
                        });

                        return (
                            <CustomMedia key={i}>
                                <Media object src={experience.logo} alt={experience.companyName} style={{width: "200px", height: "fit-content", borderRadius: "initial"}}/>
                                <Media body>
                                    <Media heading>
                                        <a href={experience.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                            {experience.companyName} <span style={{color: "grey", fontSize: 15 }}>{experience.subCompanyName}</span>
                                        </a>
                                        <span className="jobTotalDuration">{getDuration(Math.max(...totalDuration))}</span>
                                    </Media>

                                    {experience.roles.map(function (role, i) {
                                        const startDate = moment(role.startDate);
                                        const timeEnd = moment(role.currentJob ? new Date() : new Date(role.endDate));
                                        const duration = Math.round(Number(moment.duration(timeEnd.diff(startDate)).asMonths()));

                                        console.log(getDuration(duration));

                                        return <div key={i}>
                                            <h5>{role.title}</h5>
                                            <span
                                                className="jobDuration">{startDate.format('MMM YYYY')} - {role.currentJob ? 'Present' : timeEnd.format('MMM YYYY')} ({getDuration(duration)})</span>
                                            <span className="jobLocation">{role.location}</span>
                                            <p className="jobDescription">{role.description}</p>
                                        </div>
                                    })}
                                </Media>
                            </CustomMedia>
                        );
                    })}
                </Col>
            </Row>
        </Container>
    }
}

export default Experience;
